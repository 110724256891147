import { isProductionEnvironment } from './environmentDetector';
import { init as initSentryLogging, captureException } from '@sentry/browser';

let sentryLoggingIsInitiated = false;

export function initiateClientSideErrorLogging(): void {
    if (sentryLoggingIsInitiated) {
        return;
    }

    if (isProductionEnvironment()) {
        initSentryLogging({
            dsn: 'https://ca61d06c5b250d12795ec85dcccf2402@o4507033081020416.ingest.de.sentry.io/4508403412500560',
        });

        sentryLoggingIsInitiated = true;
    }
}

export function logDebug(section: string, ...itemsToLog: any | any[]): void {
    if (!isProductionEnvironment()) {
        const formattedSection = `[${section.toUpperCase()}]`;

        // prepend formatted section
        itemsToLog.unshift(formattedSection);

        // console.log.apply with null as object context, does not seem to work in IE
        try {
            // eslint-disable-next-line no-console
            console.debug.apply(null, itemsToLog);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(itemsToLog);
        }
    }
}

export function logError(
    error: Error,
    context: { [key: string]: any } = {}
): void {
    if (isProductionEnvironment()) {
        try {
            captureException(error);
        } catch (sentryError) {
            // just in case sentry fails to handle the error, as the system might be down or overloaded sometimes

            // eslint-disable-next-line no-console
            console.error(
                'Sentry exception capture failed with error: ',
                sentryError
            );
        }
    }

    // eslint-disable-next-line no-console
    console.error(error, context);
}
